import { WidgetViewerMain } from './widget-viewer-main';
import { withStyles } from '@wix/native-components-infra/dist/es/src/HOC/withStyles/withStyles';
import { withSentryErrorBoundary } from '@wix/native-components-infra/dist/es/src/HOC/sentryErrorBoundary/sentryErrorBoundary';
import { SENTRY_BOOKINGS_WIDGET_DSN } from './platform/bookings.const';

export const componentNoSentry = withStyles(WidgetViewerMain, {
  ltrCssPath: 'component.min.css',
  rtlCssPath: 'component.rtl.min.css',
});

export const component = withSentryErrorBoundary(componentNoSentry, {
  dsn: SENTRY_BOOKINGS_WIDGET_DSN,
  config: {
    environment: 'ooi-component',
  },
});
