import * as React from 'react';
import { classes } from './OfferingPrimaryInfo.st.css';
import OfferingDays from './offering-days/offering-days';
import OfferingDuration from './offering-duration/offering-duration';
import OfferingTitle from './offering-title/offering-title';
import OfferingPrice from './offering-price/offering-price';
import OfferingDescription from './offering-description/offering-description';
import {
  CatalogOfferingInjectedProps,
  withOfferingContext,
} from '../../catalog-offering-context';
import { InjectedTranslateProps, translate } from 'react-i18next';

interface PrimaryInfoProps {
  onAction(intent: string, biReferral: string): void;
  isEditorMode: boolean;
  shouldShowActionButton: boolean;
  shouldShowDescriptionText: boolean;
}

class OfferingPrimaryInfo extends React.Component<
  PrimaryInfoProps & CatalogOfferingInjectedProps & InjectedTranslateProps
> {
  shouldShowButtonAndInfoWhenOfferingExistsOrDummy = () => {
    const { offeringViewModel, isEditorMode } = this.props;
    return !(offeringViewModel.id === '-1' && !isEditorMode);
  };

  render() {
    const {
      offeringViewModel,
      onAction,
      shouldShowActionButton,
      shouldShowDescriptionText,
    } = this.props;
    const isInfoSeparatorNeeded =
      !!offeringViewModel.price && !!offeringViewModel.duration;
    return (
      <div
        className={`${classes.root} ${
          classes[offeringViewModel.tileLayout] || ''
        }`}
      >
        <OfferingTitle onAction={onAction} />
        {this.shouldShowButtonAndInfoWhenOfferingExistsOrDummy() ? (
          <div
            className={`${classes.scheduleInfoContainer} ${offeringViewModel.alignmentStyle}`}
          >
            <OfferingDuration isInfoSeparatorNeeded={isInfoSeparatorNeeded} />
            <OfferingPrice />
          </div>
        ) : null}
        <OfferingDays />
        {shouldShowActionButton &&
        this.shouldShowButtonAndInfoWhenOfferingExistsOrDummy() ? (
          <OfferingDescription
            description={
              shouldShowDescriptionText ? offeringViewModel.description : null
            }
            alignmentStyle={offeringViewModel.alignmentStyle}
            onAction={onAction}
            shouldShowActionButton={shouldShowActionButton}
          />
        ) : null}
      </div>
    );
  }
}

export default translate()(withOfferingContext()(OfferingPrimaryInfo));
