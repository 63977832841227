export const WIDGET_BI_REFERRAL = {
  WIDGET: 'widget',
  WIDGET_IMAGE: 'widget_image',
  WIDGET_TITLE: 'widget_title',
  WIDGET_DESCRIPTION: 'widget_description',
  WIDGET_BUTTON: 'widget_button',
};

export const BI_BOOKINGS_SRC = 16;
export const BI_BOOKINGS_USER_OF_USER_END_POINT = 'wixboost-ugc';
export const WIDGET_NAME_PHASE_0 = 'widget-phase-zero';
export const BI_EXPOSURE_TRIGGER_NAME = 'widget_viewer_opened';
