(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "reactDOM"], factory);
	else if(typeof exports === 'object')
		exports["component"] = factory(require("react"), require("react-dom"));
	else
		root["component"] = factory(root["React"], root["ReactDOM"]);
})((typeof self !== 'undefined' ? self : this), (__WEBPACK_EXTERNAL_MODULE__751__, __WEBPACK_EXTERNAL_MODULE__3202__) => {
return 