import imageSDK from '@wix/image-client-api/dist/imageClientSDK';
import { ImageDto } from '@wix/bookings-uou-domain';
import { getDevicePixelRatio } from './media-gallery-adapter.helper';

export const IMAGE_PIXEL_DENSITY_FACTOR = 1.5;

export const getImageUrl = (
  media: ImageDto,
  imageDimensions: { width?: number; height?: number },
) => {
  const pixelDensityFactor = getDesiredPixelDensityFactor();
  return media
    ? imageSDK.getScaleToFillImageURL(
        media.relativeUri,
        media.width,
        media.height,
        imageDimensions.width * pixelDensityFactor,
        imageDimensions.height * pixelDensityFactor,
      )
    : null;
};

const getDesiredPixelDensityFactor = () => {
  return Math.max(
    Math.floor(getDevicePixelRatio() / IMAGE_PIXEL_DENSITY_FACTOR),
    1,
  );
};
