import * as React from 'react';
import { classes } from './offering-duration.st.css';
import {
  CatalogOfferingInjectedProps,
  withOfferingContext,
} from '../../../catalog-offering-context';

interface OfferingDurationProps {
  isInfoSeparatorNeeded: boolean;
}
class OfferingDuration extends React.Component<
  CatalogOfferingInjectedProps & OfferingDurationProps
> {
  render() {
    const { offeringViewModel, isInfoSeparatorNeeded } = this.props;
    const durationModel = offeringViewModel.duration;
    return durationModel ? (
      <div
        data-hook="tile-duration"
        tabIndex={0}
        className={`${classes.root} ${
          isInfoSeparatorNeeded && classes.withSeparator
        }`}
      >
        {durationModel}
      </div>
    ) : null;
  }
}

export default withOfferingContext()(OfferingDuration);
