export enum ImageDisplayType {
  SQUARE = 'square',
  ROUND = 'round',
  NONE = 'none',
}

export enum TextAlignmentType {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
}

export enum BookItButtonStyle {
  FULL = 'full',
  BORDER = 'border',
  LINK = 'link',
}

export enum SETTINGS_KEY {
  BUTTON_TEXT = 'buttonText',
  BUTTON_THEME = 'buttonStyle',
  IMAGE_THEME = 'imageType',
  TEXT_ALIGNMENT = 'cardTextAlignment',
  SHOW_TITLE = 'displayServiceName',
  SHOW_PRICE = 'displayServicePrice',
  SHOW_DURATION = 'displayServiceDuration',
  SHOW_DESCRIPTION = 'displayServiceDescription',
  SHOW_DAYS = 'displayServiceDays',
  WIDGET_LAYOUT = 'layoutPreset',
}

export interface WidgetLayoutOptions {
  isOfferingNameVisible: boolean;
  isPriceVisible: boolean;
  isDescriptionVisible: boolean;
  isDurationVisible: boolean;
  isServiceDaysVisible: boolean;
  imageType: ImageDisplayType;
  textAlignment: TextAlignmentType;
  buttonType: BookItButtonStyle;
}

export interface WidgetDesignOptions {
  getButtonText: string;
}

export interface OfferingDisplayOptions {
  getLayout: WidgetLayoutOptions;
  getDesign: WidgetDesignOptions;
}

export const defaultSettings = {
  buttonText: null,
  buttonStyle: BookItButtonStyle.FULL,
  imageType: ImageDisplayType.SQUARE,
  cardTextAlignment: TextAlignmentType.CENTER,
  displayServiceName: true,
  displayServicePrice: true,
  displayServiceDuration: true,
  displayServiceDescription: true,
  displayServiceDays: true,
  layoutPreset: true,
};

export function getDisplayOptions(layoutParams) {
  layoutParams = layoutParams || {};
  return {
    getDesign: {
      getButtonText: layoutParams.buttonText || defaultSettings.buttonText,
    },
    getLayout: {
      isOfferingNameVisible: getRealBooleanValue(
        layoutParams,
        'displayServiceName',
      ),
      isPriceVisible: getRealBooleanValue(layoutParams, 'displayServicePrice'),
      isDescriptionVisible: getRealBooleanValue(
        layoutParams,
        'displayServiceDescription',
      ),
      isDurationVisible: getRealBooleanValue(
        layoutParams,
        'displayServiceDuration',
      ),
      isServiceDaysVisible: getRealBooleanValue(
        layoutParams,
        'displayServiceDays',
      ),
      imageType: layoutParams.imageType || defaultSettings.imageType,
      textAlignment:
        layoutParams.cardTextAlignment || defaultSettings.cardTextAlignment,
      buttonType: layoutParams.buttonStyle || defaultSettings.buttonStyle,
    },
  };
}

function getRealBooleanValue(layoutParams, key) {
  return keyHasBeenDefinedBySettings(layoutParams[key])
    ? layoutParams[key]
    : defaultSettings[key];
}

function keyHasBeenDefinedBySettings(value) {
  return value === true || value === false;
}
